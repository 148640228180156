import { Card, CardContent, Chip, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info'
import Layout from '../components/Layout'
import SEO from '../components/seo'
//import ReactQuill from 'react-quill'
import React, { useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { StringParam, useQueryParam } from "use-query-params"
import { Helmet } from 'react-helmet';
import ChipInput from 'material-ui-chip-input'


const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;
const currentDate = new Date();
const pageTitle = "Post a job on WorkWithVisa.com"

const useStyles = makeStyles((theme) => ({
    postJobButton: {
      background: "#ff424d",
      textTransform: "none",
      margin: "4px",
      color: "white"
    },
    noLink: {
      textDecoration: "none",
      color: "inherit",
    },
    buttonParent: {
        /*
        display: "flex",
        flexFlow: "row-reverse"
        */
    }
  
  }));

const PostJobPage = () => {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [tags, setTags] = useState('');
    const [token, setToken] = useQueryParam("token", StringParam)
    const currentID = token != undefined ? token : "9"+currentDate.getTime();
    console.log(currentID);

    const [jobTitleError, setJobTitleError] = useState(false); 
    const [jobApplyUrlError, setJobApplyUrlError] = useState(false); 
    const [jobTagsError, setJobTagsError] = useState(false); 
    const [posterCompanyNameError, setPosterCompanyNameError] = useState(false); 
    const [posterCompanyLocationError, setPosterCompanyLocationError] = useState(false); 
    const [posterNameError, setPosterNameError] = useState(false); 
    const [posterEmailError, setPosterEmailError] = useState(false); 

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    return(
        <Layout>
            <Helmet>
                <script src="https://js.stripe.com/v3"></script>
            </Helmet>
            <SEO title={pageTitle} description="The mission of WorkWithVisa.com is to find companies that provide Visa sponsorship for talented people."/>
            <Card>
                <CardContent>
                    <Chip
                        icon={<InfoIcon />}
                        label={pageTitle}
                        color="primary"
                        variant="outlined"
                    />
                    <div>
                        <br />
                        This is the place where you can <strong>post a job on WorkWithVisa.com</strong>!<br /><br />
                    </div>
                </CardContent>
                <CardContent>
                    <strong>1. Provide your job description:</strong><br /><br />
                    Please start typing and <b>don't forget to press SEND!</b>
                </CardContent>
                <CardContent>
                    <TextField id="job-id" label="Job ID" variant="outlined" size="small" fullWidth value={currentID} InputProps={{readOnly: true,}}/><br />
                    !! Please <strong>note down this ID</strong> if you would like to change or promote this job post in the future !!<br /><br />
                    <TextField required id="job-title" label="Job title" variant="outlined" size="small" fullWidth error={jobTitleError} onChange={event => {setJobTitleError(event.target.value == "");}} /><br /><br />
                    <ChipInput required id="job-tags" label="Job tags (max. 5)" variant="outlined" size="small" fullWidth newChipKeys="[' ', ',']" onChange={(chips) => {setTags(chips); setJobTagsError(chips.length == 0);}} error={jobTagsError} value={tags} onDelete={(chip, index) => { setTags(tags.filter((el, ind) => { index !== ind } ).join(",")); /* fix doubleclick */ } }/><br /><br />
                    <ReactQuill 
                        id="editor"
                        theme="snow" 
                        value={value} 
                        onChange={setValue} 
                        placeholder="Start typing..." 
                        style={{height: "40vh"}}
                        modules={{
                            toolbar: [
                              [{ 'header': [false] }],
                              ['bold', 'italic', 'underline','strike',],
                              [{'list': 'ordered'}, {'list': 'bullet'}, /*{'indent': '-1'}, {'indent': '+1'}*/],
                              ['link', ],
                              ['clean']
                            ],
                          }}
                    />
                    <br /><br /><br /><br />
                    <TextField required id="job-apply-url" label="Apply URL (or e-mail address)" variant="outlined" size="small" fullWidth error={jobApplyUrlError} onChange={event => {setJobApplyUrlError(event.target.value == "");}}/><br /><br />
                    <TextField required id="poster-company-name" label="Company name" variant="outlined" size="small" fullWidth error={posterCompanyNameError} onChange={event => {setPosterCompanyNameError(event.target.value == "");}}/><br /><br />
                    <TextField required id="poster-company-location" label="Location (city, country)" placeholder="Amsterdam, Netherlands" variant="outlined" size="small" fullWidth error={posterCompanyLocationError} onChange={event => {setPosterCompanyLocationError(event.target.value == "");}}/><br /><br />
                    <TextField required id="poster-name" label="Your name" variant="outlined" size="small" fullWidth error={posterNameError} onChange={event => {setPosterNameError(event.target.value == "");}}/><br /><br />
                    <TextField required id="poster-email" label="Your e-mail" variant="outlined" size="small" fullWidth error={posterEmailError} onChange={event => {setPosterEmailError(event.target.value == "");}}/><br /><br />
                    <TextField id="poster-logo" label="URL to company logo" variant="outlined" size="small" fullWidth/><br /><br />
                    <div className={classes.buttonParent}>
                        <Button color="white" className={classes.postJobButton} onClick={
                        event => {
                            event.stopPropagation()

                            let problem = false;
                            const jobTitle = document.querySelector("#job-title").value;
                            const jobApplyUrl = document.querySelector("#job-apply-url").value;
                            const posterCompanyName = document.querySelector("#poster-company-name").value;
                            const posterCompanyLocation = document.querySelector("#poster-company-location").value;
                            const posterName = document.querySelector("#poster-name").value;
                            const posterEmail = document.querySelector("#poster-email").value;

                            if (jobTitle == "") {
                                problem = true;
                                setJobTitleError(true);
                            }

                            if (jobApplyUrl == "") {
                                problem = true;
                                setJobApplyUrlError(true);
                            }

                            if (tags.length == 0) {
                                problem = true;
                                setJobTagsError(true)
                            }

                            if (posterCompanyName == "") {
                                problem = true;
                                setPosterCompanyNameError(true);
                            }

                            if (posterCompanyLocation == "") {
                                problem = true;
                                setPosterCompanyLocationError(true);
                            }

                            if (posterName == "") {
                                problem = true;
                                setPosterNameError(true);
                            }

                            if (posterEmail == "") {
                                problem = true;
                                setPosterEmailError(true);
                            }

                            if (problem) {
                                alert("Please fill in the mandatory fields!")
                                return;
                            }

                            let requestBody = {
                                job_id: currentID,
                                job_intro: value,
                                job_title: jobTitle,
                                job_tags: tags,
                                job_apply_url: jobApplyUrl,
                                poster_company_name: posterCompanyName,
                                poster_company_location: posterCompanyLocation,
                                poster_name: posterName,
                                poster_email: posterEmail,
                                poster_logo: document.querySelector("#poster-logo").value,
                                isoDate: currentDate.toISOString()
                            };
                            //window.document["lajos"] = requestBody;
                            fetch(`/.netlify/functions/send_job`, {
                                method: 'post',
                                body: JSON.stringify(requestBody)
                            })
                            .then(res => {
                                console.log(res.status, res.statusText)
                                if (res.status < 300 && res.status >= 200) {
                                    alert("Job post was saved successfully! We are working on it!")
                                } else {
                                    alert(
                                        "There was an issue with saving your introduction: " + res.statusText + "\n\n" +
                                        "Please COPY and send it in e-mail!"
                                    );
                                }
                            })
                            .catch(reason => {
                                console.error(reason)
                                alert(
                                    "There was an issue with saving your introduction: " + reason + "\n\n" +
                                    "Please COPY and send it in e-mail!"
                                );
                            })
                        }
                        }>
                            SEND
                        </Button>
                        or <i>(in case of an error)</i>
                        <Button color="white" className={classes.postJobButton} onClick={
                        event => {
                            event.stopPropagation();
                            
                            let problem = false;
                            const jobTitle = document.querySelector("#job-title").value;
                            const jobApplyUrl = document.querySelector("#job-apply-url").value;
                            const posterCompanyName = document.querySelector("#poster-company-name").value;
                            const posterCompanyLocation = document.querySelector("#poster-company-location").value;
                            const posterName = document.querySelector("#poster-name").value;
                            const posterEmail = document.querySelector("#poster-email").value;

                            if (jobTitle == "") {
                                problem = true;
                                setJobTitleError(true);
                            }

                            if (jobApplyUrl == "") {
                                problem = true;
                                setJobApplyUrlError(true);
                            }

                            if (tags.length == 0) {
                                problem = true;
                                setJobTagsError(true)
                            }

                            if (posterCompanyName == "") {
                                problem = true;
                                setPosterCompanyNameError(true);
                            }

                            if (posterCompanyLocation == "") {
                                problem = true;
                                setPosterCompanyLocationError(true);
                            }

                            if (posterName == "") {
                                problem = true;
                                setPosterNameError(true);
                            }

                            if (posterEmail == "") {
                                problem = true;
                                setPosterEmailError(true);
                            }

                            if (problem) {
                                alert("Please fill in the mandatory fields!")
                                return;
                            }

                            let requestBody = {
                                job_id: currentID,
                                job_intro: value,
                                job_title: jobTitle,
                                job_tags: tags,
                                job_apply_url: jobApplyUrl,
                                poster_company_name: posterCompanyName,
                                poster_company_location: posterCompanyLocation,
                                poster_name: posterName,
                                poster_email: posterEmail,
                                poster_logo: document.querySelector("#poster-logo").value,
                                isoDate: currentDate.toISOString()
                            };
                            let coded = btoa(JSON.stringify(requestBody));
                            copyToClipboard(coded);
                            window.location.href = "mailto:job@workwithvisa.com?subject=" + 
                                encodeURIComponent("New job post") + 
                                "&body=" + 
                                encodeURIComponent(
                                    "Hi WorkWithVisa.com team,\n\n" +
                                    "there was an issue with saving my job posting on the page.\n" + 
                                    "I am forwarding it to you by e-mail.\n\n" +
                                    "Token:\n" + 
                                    "----------\n" +
                                    currentID + "\n" +
                                    "----------\n\n" +
                                    "Introduction:\n" +
                                    "----------\n" +
                                    coded + "\n" +
                                    "----------\n\n" +
                                    "Best regards,\n"
                                );
                        }
                        }>
                            COPY
                        </Button>
                        and send in e-mail to <a href="mailto:job@workwithvisa.com">job@workwithvisa.com</a>.
                    </div>
                </CardContent>
                <CardContent>
                    <strong>2. Choose your promotion option: </strong><i>(optional) </i><br />
                    <ul>
                        <li>
                            <Button color="white" className={classes.postJobButton} onClick={
                                event => {
                                    event.stopPropagation()
                                    var stripe = Stripe('pk_live_51IZdTzLcjCBIaiDWrV2lnZuiUUG32UqJdePjYMtcuGDLBYZVue0XJU7iy9iYMBWvMjaDuN4MdTEHSA2eYCRixPCk00oTGnipld');

                                    /*
                                    * When the customer clicks on the button, redirect
                                    * them to Checkout.
                                    */
                                    stripe.redirectToCheckout({
                                        lineItems: [{price: 'price_1JJvq7LcjCBIaiDWnLsCLdCO', quantity: 1}],
                                        mode: 'payment',
                                        /*
                                        * Do not rely on the redirect to the successUrl for fulfilling
                                        * purchases, customers may not always reach the success_url after
                                        * a successful payment.
                                        * Instead use one of the strategies described in
                                        * https://stripe.com/docs/payments/checkout/fulfill-orders
                                        */
                                        successUrl: 'https://workwithvisa.com/verified_partner/successful_payment',
                                        cancelUrl: 'https://workwithvisa.com/verified_partner/cancelled_payment',
                                        clientReferenceId: currentID
                                    })
                                    .then(function (result) {
                                        if (result.error) {
                                            /*
                                            * If `redirectToCheckout` fails due to a browser or network
                                            * error, display the localized error message to your customer.
                                            */
                                            alert("An error has happened:\n\n"+currentID+"\n\nPlease try again!\nIf the issue still exists, please contact us at info@workwithvisa.com!");
                                        }
                                    });
                                }
                                }>
                                    Main page promotion!
                            </Button>
                            <i>(One-time payment, <strong>99 EUR for 28 days</strong>, "Promotion" section on the main page)</i>
                        </li>
                        <li>
                            <Button color="white" className={classes.postJobButton} onClick={
                                event => {
                                    event.stopPropagation()
                                    var stripe = Stripe('pk_live_51IZdTzLcjCBIaiDWrV2lnZuiUUG32UqJdePjYMtcuGDLBYZVue0XJU7iy9iYMBWvMjaDuN4MdTEHSA2eYCRixPCk00oTGnipld');
                                    /*
                                    * When the customer clicks on the button, redirect
                                    * them to Checkout.
                                    */
                                    stripe.redirectToCheckout({
                                        lineItems: [{price: 'price_1JJvrrLcjCBIaiDWOnTgVbPJ', quantity: 1}],
                                        mode: 'payment',
                                        /*
                                        * Do not rely on the redirect to the successUrl for fulfilling
                                        * purchases, customers may not always reach the success_url after
                                        * a successful payment.
                                        * Instead use one of the strategies described in
                                        * https://stripe.com/docs/payments/checkout/fulfill-orders
                                        */
                                        successUrl: 'https://workwithvisa.com/verified_partner/successful_payment',
                                        cancelUrl: 'https://workwithvisa.com/verified_partner/cancelled_payment',
                                        clientReferenceId: currentID
                                    })
                                    .then(function (result) {
                                        if (result.error) {
                                        /*
                                        * If `redirectToCheckout` fails due to a browser or network
                                        * error, display the localized error message to your customer.
                                        */
                                        alert("An error has happened:\n\n"+currentID+"\n\nPlease try again!\nIf the issue still exists, please contact us at info@workwithvisa.com!");
                                        }
                                    });
                                }
                                }>
                                    Country highlight!
                            </Button>
                            <i>(One-time payment: <strong>199 EUR for 7 days</strong>, visible on top of every job offer from the chosen country (exclusively) + on the country summary page + shared 2x/week on social media)</i>
                        </li>
                    </ul>
                </CardContent>
                <CardContent>
                    <strong>3. Good to know:</strong><br /><br />
                    <ul>
                        <li>Would you like to <strong>post a new job offer</strong>? Please <strong>refresh the page</strong>!</li>
                        <li>Would you like to <strong>promote an already posted job</strong>? For the next steps please <strong>send us an e-mail</strong> to <a href="mailto:job@workwithvisa.com">job@workwithvisa.com</a> with the <strong>job page URL</strong> or with the <strong>job ID</strong>!</li>
                    </ul>
                </CardContent>
            </Card>
        </Layout>
    )
}

export default PostJobPage
